import React from "react"
import styled from "@emotion/styled"
import tw from "twin.macro"

import Header from "../components/header/Header"
import Layout from "../components/Layout"
import Backpack from "../components/Backpack"
import SEO from "../components/SEO"
import LinkGatsby from "../components/LinkGatsby"

const LayoutHome = styled(Layout)`
  ${tw`justify-between`}
`

const H2 = styled.h2`
  ${tw`text-primary text-5xl font-bold font-sans`}

  line-height: 3rem
`

const P = styled.p`
  ${tw`text-lg text-paragraph leading-9 font-sans mt-10`}
`

const Body = styled.div`
  ${tw`mx-auto lg:mx-0 mt-16 lg:mt-0 flex flex-col lg:flex-row justify-between items-center lg:mb-48 w-full md:w-5/6 z-0`}
`

const Message = styled.div`
  ${tw`lg:w-5/12 mb-24 lg:mb-0`}
`

export default function Home() {
  return (
    <LayoutHome>
      <SEO title="404 - Página não encontrada" />
      <Header />
      <Body>
        <Message>
          <H2>404 - Página não encontrada</H2>
          <P>
            A página que você está tentando acessar não existe. Tente voltar
            para a <LinkGatsby to="/">página inicial</LinkGatsby> ou faça uma
            busca por uma cidade.
          </P>
        </Message>
        <Backpack mood="ko" />
      </Body>
    </LayoutHome>
  )
}
